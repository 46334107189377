<template>
  <ag-card-container
    :search.sync="search"
    title="Liste des rôles"
    fluid
  >
    <template #left>
      <v-btn
        color="success"
        fab
        x-small
        :loading="loadingExport"
        @click="exportExcel"
      >
        <v-icon>file_download</v-icon>
      </v-btn>
    </template>

    <role-list
      :value="roleList"
      :loading="loadingData"
      :search="search"
    />
  </ag-card-container>
</template>

<script>
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import AgCardContainer from 'src/components/common/AgCardContainer'
import { createExcelFile } from 'src/services/drawExcelService'
import RoleList from '../components/RoleList'

export default {
  name: 'RoleListContainer',
  components: {
    AgCardContainer,
    RoleList,
  },
  data: () => ({
    roleList: [],
    search: '',
    loadingData: false,
    loadingExport: false,
  }),
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.fetchData()
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData () {
      try {
        this.loadingData = true
        this.roleList = await Repositories.role.getList()
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
    async exportExcel () {
      try {
        this.loadingExport = true
        const exportConfig = {
          columns: [
            { header: 'Nom', key: 'name' },
            { header: 'Id', key: '_id' },
          ],
          view: [{ state: 'frozen', xSplit: 0, ySplit: 1 }],
          sheetName: 'Liste des rôles',
        }
        const data = this.roleList.map(item => ({
          _id: item._id,
          name: item.name,
        }))
        await createExcelFile([{ dataToExport: data, config: exportConfig }], 'Liste des rôles')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingExport = false
      }
    },
  },
}
</script>
